var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"intro-y tab-content mt-5"},[_c('div',{staticClass:"tab-content__pane active"},[_c('div',{staticClass:"grid grid-cols-12 gap-6"},[_c('div',{staticClass:"intro-y box col-span-12"},[_c('div',{staticClass:"flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Operator History")]),_c('div',{staticClass:"ml-auto"},[_c('button',{staticClass:"button text-white bg-theme-11 shadow-md mr-2 text-xs sm:text-sm cursor-pointer",on:{"click":_vm.onGenerateReport}},[_vm._v(" Download Report ")])])]),_c('div',{staticClass:"px-1"},[(_vm.operatorHistoryData.length !== 0)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(0),_c('tbody',_vm._l((_vm.operatorHistoryData.slice(
                      this.assetsStartIndex,
                      this.assetsEndIndex
                    )),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in",on:{"click":function($event){return _vm.onShowModal(index)}}},[_c('td',[_c('span',{staticClass:"font-medium whitespace-no-wrap"},[_vm._v(_vm._s(asset.tag == "tag_out" ? "Tagged Out" : "Tagged In"))])]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"font-medium whitespace-no-wrap text-white text-xs rounded px-2 py-1",class:{
                          'bg-theme-6': asset.tag == 'tag_out',
                          'bg-theme-9': asset.tag == 'tag_in',
                        }},[_vm._v(_vm._s(_vm.moment(asset.time).format("DD-MM-YYYY hh:mm a")))])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.basicDetailData.assets ? _vm.basicDetailData.assets[asset.asset_id].brand : "- -")+" "+_vm._s(_vm.basicDetailData.assets ? _vm.basicDetailData.assets[asset.asset_id].model : "- -")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.moment(asset.tag_in).format("DD-MM-YYYY hh:mm a"))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.moment(asset.tag_out).format("DD-MM-YYYY hh:mm a"))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(asset.total || "- -")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(asset.overtime || "- -")+" ")])])}),0)])]):_vm._e(),(_vm.operatorHistoryData.length !== 0)?_c('div',{staticClass:"intro-y flex flex-wrap sm:flex-row sm:flex-no-wrap items-center mt-3"},[_c('ul',{staticClass:"pagination"},[_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onPrevPagination}},[_c('ChevronLeftIcon',{staticClass:"w-4 h-4",class:{
                        'text-gray-700 cursor-not-allowed':
                          _vm.assetsCurrentPage == 1,
                      }})],1)]),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.assetsStartIndex + 1)+"-"+_vm._s(_vm.assetsEndIndex))])]),_vm._m(1),_c('li',[_c('span',{staticClass:"pagination__link pagination__link--active"},[_vm._v(_vm._s(_vm.assetsLength))])]),_c('li',[_c('span',{staticClass:"pagination__link",on:{"click":_vm.onNextPagination}},[_c('ChevronRightIcon',{staticClass:"w-4 h-4",class:{
                        'text-gray-700 cursor-not-allowed':
                          _vm.assetsCurrentPage == _vm.assetsTotalPages,
                      }})],1)])])]):_vm._e()])])])])]),_c('OperatorModal',{attrs:{"showModal":_vm.showModal,"onHideModal":_vm.onHideModal,"basicDetailData":_vm.operatorHistoryData[_vm.selectedDataIndex]}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("EVENT")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TIME")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("ASSET")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v(" TAG IN TIME ")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v(" TAG OUT TIME ")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v(" USAGE HOURS ")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("OVERTIME")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('span',{staticClass:"pagination__link"},[_vm._v("of")])])}]

export { render, staticRenderFns }