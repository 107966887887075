
























































import { Component, Vue } from "vue-property-decorator";
import { ArrowLeftIcon, RefreshCcwIcon, EditIcon } from "vue-feather-icons";

import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";
import OperatorDetailPreview from "../components/operator-detail/Operator-Detail-Preview.vue";
import OperatorHistory from "../components/operator-detail/Operator-History.vue";

@Component({
  components: {
    ArrowLeftIcon,
    RefreshCcwIcon,
    Loader,
    NoData,
    OperatorDetailPreview,
    OperatorHistory,
    EditIcon,
  },
})
export default class TagInOutDetail extends Vue {
  isLoading = false;
  showNoData = false;
  noDataType = "Error";
  basicDetailData = "";
  operatorHistoryData = "";
  selectedTab = 0;

  mounted() {
    this.fetchAssetWithId();
  }

  reloadData() {
    this.isLoading = true;
    this.showNoData = false;
    this.fetchAssetWithId();
    this.selectedTab = 0;
  }

  onChangeTab(index: number) {
    this.selectedTab = index;
  }

  async fetchAssetWithId() {
    const id = this.$router.currentRoute.params.id;
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
        this.fetchOperatorHistoryWithId();
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  async fetchOperatorHistoryWithId() {
    const id = this.$router.currentRoute.params.id;
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator/history/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.operatorHistoryData = data;
      } else {
        this.operatorHistoryData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.operatorHistoryData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }
}
