




























































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ExternalLinkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "vue-feather-icons";
import OperatorModal from "./Operator-Modal.vue";
import XLSX from "xlsx";
import moment from "moment";

@Component({
  components: {
    ExternalLinkIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    OperatorModal,
  },
})
export default class OperatorHistory extends Vue {
  @Prop() basicDetailData!: any;

  operatorHistoryData: any = [];

  showModal = false;
  onHideModal() {
    this.showModal = false;
  }

  selectedDataIndex = 0;
  onShowModal(index) {
    this.selectedDataIndex = index;
    setTimeout(() => {
      this.showModal = true;
    }, 100);
  }

  mounted() {
    if (this.basicDetailData.tagHistory) {
      this.operatorHistoryData = [...this.basicDetailData.tagHistory.reverse()];
    }
    this.assetsLength = this.operatorHistoryData.length;
    this.initPaginations(this.assetsLength);
  }

  assetsTotalPages = 1;
  assetsCurrentPage = 1;
  assetsStartIndex = 0;
  assetsEndIndex = 0;
  assetsLength = 0;
  initPaginations(length: number) {
    this.assetsEndIndex = length > 44 ? 44 : length;
    this.assetsTotalPages =
      this.operatorHistoryData.length % 44 === 0
        ? Math.floor(this.operatorHistoryData.length / 44)
        : Math.floor(this.operatorHistoryData.length / 44 + 1);
  }

  onNextPagination() {
    if (this.assetsCurrentPage >= this.assetsTotalPages) return;

    this.assetsCurrentPage += 1;
    this.assetsStartIndex = this.assetsEndIndex;
    this.assetsEndIndex =
      this.operatorHistoryData.length > this.assetsStartIndex + 44
        ? this.assetsStartIndex + 44
        : this.operatorHistoryData.length;
  }

  onPrevPagination() {
    if (this.assetsCurrentPage <= 1) return;

    this.assetsCurrentPage -= 1;
    this.assetsEndIndex = this.assetsStartIndex;
    this.assetsStartIndex =
      this.assetsStartIndex - 44 <= 0 ? 0 : this.assetsStartIndex - 44;
  }

  onGenerateReport() {
    if (this.operatorHistoryData.length > 0) {
      const data = XLSX.utils.json_to_sheet(
        this.operatorHistoryData.map((x) => ({
          TIME: moment(x.time).format("DD-MM-YYYY hh:mm a"),
          ASSET:
            this.basicDetailData.assets[x.asset_id].brand +
            " " +
            this.basicDetailData.assets[x.asset_id].model,
          "TAG IN TIME": moment(x.tag_in).format("DD-MM-YYYY hh:mm a"),
          "TAG OUT TIME": moment(x.tag_out).format("DD-MM-YYYY hh:mm a"),
          "USAGE HOURS": x.total,
          OVERTIME: x.overtime,
        }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "operator_history.xlsx");
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Failed!",
        text: "No operator history found",
        type: "Danger",
      });
    }
  }
}
