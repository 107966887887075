

















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OperatorModal extends Vue {
  @Prop() basicDetailData!: any;
  @Prop() showModal = false;
  @Prop() onHideModal!: Function;
}
