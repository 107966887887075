var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6"},[_c('div',{staticClass:"col-span-12 mt-8"},[_c('div',{staticClass:"intro-y flex items-center h-10"},[_c('div',{staticClass:"intro-y flex items-center h-10"},[_c('ArrowLeftIcon',{staticClass:"sm:w-6 sm:h-6 sm:mr-6 w-5 h-5 mr-4 cursor-pointer",on:{"click":function($event){return _vm.$router.back()}}}),_c('h2',{staticClass:"sm:text-2xl text-xl font-medium truncate mr-9"},[_vm._v(" Operator Detail ")])],1),_c('div',{staticClass:"sm:flex items-center ml-auto mt-0 hidden"},[_c('span',{staticClass:"ml-auto flex items-center text-theme-9 dark:text-theme-9 cursor-pointer mr-6",on:{"click":function($event){return _vm.$router.push({
              path: ("/asset-tracker/edit-operator/" + (_vm.basicDetailData.id)),
            })}}},[_c('EditIcon',{staticClass:"w-4 h-4 mr-3"}),_vm._v(" Update Operator ")],1),_c('span',{staticClass:"ml-auto flex items-center text-theme-1 dark:text-theme-10 cursor-pointer",on:{"click":_vm.reloadData}},[_c('RefreshCcwIcon',{staticClass:"w-4 h-4 mr-3"}),_vm._v(" Reload Data ")],1)])]),(_vm.isLoading)?_c('Loader'):_vm._e(),(!_vm.isLoading && _vm.showNoData && _vm.operatorHistoryData)?_c('NoData',{attrs:{"onReloadData":_vm.reloadData,"type":_vm.noDataType}}):_vm._e(),(!_vm.isLoading && _vm.basicDetailData && _vm.operatorHistoryData)?_c('OperatorDetailPreview',{attrs:{"basicDetailData":_vm.basicDetailData,"onChangeTab":_vm.onChangeTab}}):_vm._e(),(
        !_vm.isLoading &&
        _vm.basicDetailData &&
        _vm.operatorHistoryData &&
        _vm.selectedTab === 0
      )?_c('OperatorHistory',{attrs:{"basicDetailData":_vm.operatorHistoryData}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }