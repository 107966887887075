










































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  PhoneIcon,
  CreditCardIcon,
  BriefcaseIcon,
  TruckIcon,
  DollarSignIcon,
  CalendarIcon,
  UserIcon,
  BarChart2Icon,
  TagIcon,
} from "vue-feather-icons";

@Component({
  components: {
    PhoneIcon,
    CreditCardIcon,
    BriefcaseIcon,
    TruckIcon,
    DollarSignIcon,
    CalendarIcon,
    UserIcon,
    BarChart2Icon,
    TagIcon,
  },
})
export default class OperatorDetailPreview extends Vue {
  @Prop() basicDetailData!: {};
  @Prop() onChangeTab!: Function;

  activeTab = 0;
  tabs = ["History"];

  onClickTab(index: number) {
    this.activeTab = index;
    this.onChangeTab(index);
  }
}
